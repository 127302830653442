import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d1e32a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-5 d-flex justify-center" }
const _hoisted_2 = ["href", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "icon-container",
        key: index
      }, [
        _createVNode(_component_v_avatar, {
          size: "large",
          rounded: "0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              href: link.url,
              target: "_blank",
              title: link.title
            }, [
              _createVNode(_component_v_img, {
                class: "link-image",
                src: require(`../assets/${link.src}`)
              }, null, 8, ["src"])
            ], 8, _hoisted_2)
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}