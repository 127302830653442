
import { defineComponent } from "vue";

export default defineComponent({
  name: "PersonalLinks",
  data() {
    return {
      links: [
        {
          src: "linkIcons/linkedin.png",
          title: "LinkedIn",
          url: "https://www.linkedin.com/in/camden-obertop-b49375190/",
        },
        {
          src: "linkIcons/itchio.png",
          title: "itch.io",
          url: "https://camdeno.itch.io/",
        },
        {
          src: "linkIcons/github.png",
          title: "GitHub",
          url: "https://github.com/camden-obertop",
        },
      ],
    };
  },
});
