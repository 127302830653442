import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd1ac49c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "text-h3 text-center pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectCard = _resolveComponent("ProjectCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.sectionName), 1),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, index) => {
        return (_openBlock(), _createBlock(_component_ProjectCard, {
          key: index,
          project: project,
          projectIndex: index,
          class: "projectDisplay"
        }, null, 8, ["project", "projectIndex"]))
      }), 128))
    ])
  ]))
}