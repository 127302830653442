
import { defineComponent } from "vue";
import ProjectCard from "./ProjectCard.vue";

export default defineComponent({
  name: "ProjectList",
  components: {
    ProjectCard,
  },
  props: {
    sectionName: {
      type: String,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
  },
});
