import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fabbdc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_AboutMe = _resolveComponent("AboutMe")!
  const _component_PersonalLinks = _resolveComponent("PersonalLinks")!
  const _component_ProjectList = _resolveComponent("ProjectList")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_app_bar, { color: "darker" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "darker",
          href: "#about",
          link: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(" About Me ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          color: "darker",
          href: "#projects",
          link: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Projects ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          color: "darker",
          text: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Resume ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer)
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { "no-gutters": "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: _ctx.cols,
              offset: _ctx.offset
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AboutMe, {
                  id: "about",
                  class: "scrollTarget"
                }),
                _createVNode(_component_PersonalLinks),
                _createVNode(_component_ProjectList, {
                  id: "projects",
                  class: "scrollTarget projectList",
                  "section-name": "Personal Projects",
                  projects: _ctx.ongoingProjects
                }, null, 8, ["projects"]),
                _createVNode(_component_ProjectList, {
                  class: "projectList",
                  "section-name": "Hackathon and Game Jam Projects",
                  projects: _ctx.jamProjects
                }, null, 8, ["projects"]),
                _createVNode(_component_ProjectList, {
                  class: "projectList",
                  "section-name": "Open Source Projects",
                  projects: _ctx.openSourceProjects
                }, null, 8, ["projects"])
              ]),
              _: 1
            }, 8, ["cols", "offset"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}