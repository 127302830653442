import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d4c6324"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-no-wrap" }
const _hoisted_2 = { class: "py-2 d-flex flex-column justify-space-between" }
const _hoisted_3 = { class: "text--primary" }
const _hoisted_4 = { class: "text--primary" }
const _hoisted_5 = { class: "d-flex justify-space-between flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.mobile)
      ? (_openBlock(), _createBlock(_component_v_hover, { key: 0 }, {
          default: _withCtx(({ isHovering, props }) => [
            _createVNode(_component_v_card, _mergeProps(props, {
              elevation: isHovering ? 24 : 2,
              color: "darker",
              dark: ""
            }), {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_v_avatar, {
                    class: "ma-3 mr-1",
                    size: _ctx.avatarSize,
                    rounded: "0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_img, {
                        class: "project-image",
                        "aspect-ratio": "1",
                        cover: "",
                        src: require(`../assets/${_ctx.project.src}`)
                      }, null, 8, ["src"])
                    ]),
                    _: 1
                  }, 8, ["size"]),
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_v_card_title, { class: "pl-2 text-h5" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.project.title), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, { class: "pl-2 pb-0" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.project.description), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    (_ctx.project.links)
                      ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 0 }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.links, (link, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "mr-5",
                                key: index
                              }, [
                                _createVNode(_component_v_btn, {
                                  link: "",
                                  href: link.url,
                                  target: "_blank",
                                  variant: "flat",
                                  color: "dark"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(link.text), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["href"])
                              ]))
                            }), 128))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 2
            }, 1040, ["elevation"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_card, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_img, {
              "aspect-ratio": "1",
              cover: "",
              src: require(`../assets/${_ctx.project.src}`)
            }, null, 8, ["src"]),
            _createVNode(_component_v_card_title, { class: "pl-2 text-h5" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.project.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "pl-2 pb-0" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.project.description), 1)
              ]),
              _: 1
            }),
            (_ctx.project.links)
              ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.links, (link, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "mt-2",
                          key: index
                        }, [
                          _createVNode(_component_v_btn, {
                            link: "",
                            href: link.url,
                            target: "_blank",
                            variant: "flat",
                            color: "dark"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(link.text), 1)
                            ]),
                            _: 2
                          }, 1032, ["href"])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
  ]))
}